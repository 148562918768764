<template>
  <div class="record">
    <ShopNavBar title="申请记录" />

    <div class="tabs">
      <van-tabs v-model:active="active" @click-tab="tabChange">
        <van-tab name="99" title="全部"></van-tab>
        <van-tab name="0" title="待审核"></van-tab>
        <van-tab name="1" title="已审核"></van-tab>
        <van-tab name="2" title="已驳回"></van-tab>
      </van-tabs>
    </div>

    <van-list v-if="list.length > 0" v-model:loading="loading" :finished="finished" :immediate-check="false" offset="10"
      finished-text="没有更多了" @load="onLoad">
      <div class="record-list">
        <div class="record-item" v-for="item in list" :key="item.id" @click="tolink('./applyDetails?id=' + item.id)">
          <div class="row justify-between">
            <div>申请名称：{{ item.shopName }}</div>
            <van-tag color="#ff2d2e" v-if="item.state == 0">{{ item.stateName }}</van-tag>
            <van-tag type="success" v-if="item.state == 1">{{ item.stateName }}</van-tag>
            <van-tag color="gray" v-if="item.state == 2">{{ item.stateName }}</van-tag>
          </div>
          <div class="row justify-between q-mt-xs">申请时间：{{ item.createTime }}</div>
          <div class="row justify-end q-mt-xs" v-if="item.zhiyaState == 0">
            <div class="btn" @click.stop="applyBackAmount(item.id)">申请退回质押</div>
          </div>
          <div class="row justify-between q-mt-xs" v-else>
            <div>申请退回质押：{{ item.zhiyaStateName }}</div>
          </div>
        </div>
      </div>
    </van-list>

    <van-empty description="暂无记录" v-if="list.length == 0" />
  </div>
</template>

<script>
import { Tab, Tabs, Tag, List, Empty } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Tag.name]: Tag,
    [List.name]: List,
    [Empty.name]: Empty,
  },
  setup() {
    return {
      us: ref(null),
      active: ref(99),
      loading: ref(false),
      finished: ref(false),
      pageIndex: ref(1),
      list: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getdata()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    onLoad() {
      this.loading = true
      this.pageIndex++
      this.getdata()
    },
    tabChange(e) {
      this.loading = false
      this.finished = false
      this.pageIndex = 1
      this.list = []
      this.getdata()
    },
    getdata() {
      let _this = this
      _this.$request.post(
        "api/UsersShopApply/List",
        {
          userid: _this.us.userid,
          pageIndex: _this.pageIndex,
          pageSize: 10,
          state: _this.active
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            _this.list = data
            // console.log(data)
          }
        }
      )
    },
    applyBackAmount(id) {
      let _this = this
      this.$q.dialog({
				message: "确定要申请退回质押吗？",
        cancel: true,
			}).onOk(() => {
        _this.$request.post(
          "api/UsersShopApply/ApplyBackAmount",
          {
            
            userid: _this.us.userid,
            id
          },
          (res) => {
            _this.$q.dialog({
              message: res.data.msg,
            })
            if(res.data.code == 100) {
              _this.loading = false
              _this.finished = false
              _this.pageIndex = 1
              _this.list = []
              _this.getdata()
            }
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.record {
  padding: 44px 10px 10px;
  min-height: 100vh;
  background: rgba(242, 242, 242, 1);
}

.tabs {
  position: fixed;
  top: 44px;
  left: 0;
  width: 100%;
  z-index: 9;
}

.record-item {
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.btn {
  padding: 2px 12px;
  border-radius: 6px;
  color: #fff;
  background-color: rgb(255, 45, 46);
}
</style>